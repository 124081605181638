import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency',
})
export class CurrencyPipe implements PipeTransform {
  transform(value: number, decimals: number = 2): string {
    if (value === null || value === undefined || isNaN(value)) {
      return 'S/. 0.00';
    }

    return `S/. ${value.toFixed(decimals)}`;
  }
}
