import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import HttpVerbs = Enums.HttpVerbs;
import HttpStatus = Enums.HttpStatus;
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Enums } from 'src/app/helper/enums';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoaderService } from 'src/app/service/loader.service';
import { CustomFunction } from 'src/app/helper/custom-function';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Injectable()
export class GlobalInterceptor implements HttpInterceptor {
  constructor(
    private snackbar: MatSnackBar,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();

    const tokenIsValid = this.authenticationService.tokenIsValid();

    request = this.addHeaders(tokenIsValid, request);

    return next.handle(request).pipe(
      map((response: HttpResponse<any>) => {
        CustomFunction.addToConsole(
          `GlobalInterceptor: The '${request.method}' request is starting: ${request.urlWithParams}`,
        );

        if (!response.status || !request.method) return;

        if (
          (response.status === HttpStatus.NO_CONTENT ||
            response.status === HttpStatus.OK ||
            response.status === HttpStatus.CREATED) &&
          (request.method === HttpVerbs.PUT ||
            request.method === HttpVerbs.DELETE ||
            request.method === HttpVerbs.POST)
        ) {
          let action = 'acción';

          if (
            (response.status === HttpStatus.NO_CONTENT &&
              request.method === HttpVerbs.POST) ||
            (response.status === HttpStatus.CREATED &&
              request.method === HttpVerbs.POST)
          ) {
            action = 'creación';
          }

          if (
            response.status === HttpStatus.NO_CONTENT &&
            request.method === HttpVerbs.PUT
          ) {
            action = 'actualización';
          }

          if (
            response.status === HttpStatus.NO_CONTENT &&
            request.method === HttpVerbs.DELETE
          ) {
            action = 'eliminación';
          }

          if (!request.url.includes(`${Enums.ApiPath.NOTIFICATION}/readed`)) {
            setTimeout(() => {
              this.snackbar.open(
                `Se realizó la ${action} solicitada de manera satisfactoria`,
                'EXITO!!',
                {
                  duration: 4000,
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                },
              );
            }, 1000);
          }
        }

        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        CustomFunction.addToConsole(
          'GlobalInterceptor catchError:',
          error,
          true,
        );

        switch (error.status) {
          case Enums.HttpStatus.UNAUTHORIZED:
            const tokenIsValid = this.authenticationService.tokenIsValid();

            if (tokenIsValid) {
              const token = this.authenticationService.getToken();

              this.authenticationService.logout(token).toPromise().then();

              errorMessage =
                'Su sesión ha expirado, vuelva a ingresar al sistema';
            } else {
              errorMessage = `No está autorizado para consumir este recurso: ${error.url}`;
            }
            break;

          case Enums.HttpStatus.METHOD_NOT_ALLOWED:
            errorMessage =
              'Usuario no tiene PERMISOS para la ejecución de dicho Método';
            break;

          case Enums.HttpStatus.BAD_REQUEST:
            if (error.error.type === Enums.ContentType.JSON_PROBLEM) {
              this.deserializeBlobError(error.error).then();
              break;
            }

            if (error.error.errors) {
              Object.keys(error.error.errors).forEach((key) => {
                errorMessage += `\n${key}: ${error.error.errors[key]}`;
              });
            }
            break;

          case Enums.HttpStatus.FORBIDDEN:
            errorMessage = 'El endpoint que se quiere consumir esta prohibido';
            break;

          case Enums.HttpStatus.NOT_FOUND:
            errorMessage = `No se encontró el recurso: ${error.url}`;
            break;

          case HttpStatus.EXPECTATION_FAILED:
            if (error.error.constructor.name === 'Blob') {
              error.error.text().then((data: string) => {
                if (data) {
                  this.snackbar.open(data, null, {
                    duration: 10000,
                  });
                }
              });
            } else {
              errorMessage = error.error;
            }
            break;

          case Enums.HttpStatus.INTERNAL_SERVER_ERROR:
            if (error.error.constructor.name === 'Blob') {
              this.deserializeBlobError(error.error).then();
              break;
            }

            errorMessage = error.error;
            break;

          case Enums.HttpStatus.TO_MANY_REQUEST:
            errorMessage =
              'Se están generando muchas peticiones, contacte a soporte técnico';
            break;

          default:
            errorMessage = `Ocurrio un error inesperado`;
            break;
        }

        if (errorMessage && errorMessage !== '') {
          this.snackbar.open(errorMessage, null, {
            duration: 10000,
          });
        }

        return of(null);
      }),
      finalize(() => {
        this.loaderService.hide();

        CustomFunction.addToConsole(
          `GlobalInterceptor: The '${request.method}' request is over: ${request.urlWithParams}`,
        );
      }),
    );
  }

  private addHeaders(
    tokenIsValid: boolean,
    request: HttpRequest<any>,
  ): HttpRequest<any> {
    let headers = new HttpHeaders({});

    headers = headers.append('Application-Client', 'WEB INTRANET EXPHADIS');

    if (request.url.includes(`${Enums.ApiPath.REPORT}`)) {
      headers = headers.append('Accept', '*/*');
    } else {
      headers = headers.append('Content-Type', Enums.ContentType.JSON);
      headers = headers.append('Accept', Enums.ContentType.JSON);
    }

    if (tokenIsValid) {
      const token = this.authenticationService.getToken();
      const username = this.authenticationService.getUsername();
      const completeName = this.authenticationService.getCompleteName();

      headers = headers.append('Authorization', `Bearer ${token}`);
      headers = headers.append('Username', username);
      headers = headers.append(
        'Complete-Name',
        CustomFunction.removeAccents(completeName),
      );
    } else {
      if (
        request.url.indexOf('login') >= 0 &&
        request.url.indexOf('login-third-party') === -1
      ) {
        headers = headers.append(
          'Username',
          CustomFunction.deserialize(request.body).username,
        );
      }
    }

    const ip = this.authenticationService.getIp();

    if (ip) {
      headers = headers.append('Ip', ip);
    }

    let options;
    if (request.url.includes(`${Enums.ApiPath.REPORT}`)) {
      options = {
        headers: headers,
        responseType: 'blob' as 'json',
      };
    } else {
      options = {
        headers: headers,
      };
    }

    if (request.method === HttpVerbs.POST || request.method === HttpVerbs.PUT) {
      request = new HttpRequest<any>(
        request.method,
        request.url,
        request.body,
        options,
      );
    } else if (
      request.method === HttpVerbs.GET ||
      request.method === HttpVerbs.DELETE
    ) {
      request = new HttpRequest<any>(
        request.method,
        request.url,
        null,
        options,
      );
    } else {
      throw new Error(`Unsupported HTTP method: ${request.method}`);
    }

    return request;
  }

  private async deserializeBlobError(error: any) {
    error.text().then((data: string) => {
      if (data) {
        const response = CustomFunction.deserialize(data);

        let errorMessage = '';

        if (response.errors) {
          Object.keys(response.errors).forEach((key) => {
            errorMessage += `\n${key}: ${response.errors[key]}`;
          });
        }

        if (errorMessage && errorMessage !== '') {
          this.snackbar.open(errorMessage, null, {
            duration: 10000,
          });
        }

        if (response.message) {
          this.snackbar.open(response.message, null, {
            duration: 10000,
          });
        }
      }
    });
  }
}
