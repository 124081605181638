import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './core/guard/auth-guard';
import { LayoutModule } from './layout/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { FirestoreModule } from '@angular/fire/firestore';
import { ErrorHandler, NgModule, Provider } from '@angular/core';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { GlobalInterceptor } from './core/interceptor/global.interceptor';
import { CustomErrorHandler } from './core/handlers/custom.error.handler';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const providers: Provider[] = [];

providers.push({
  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  useValue: {
    appearance: 'fill',
  } as MatFormFieldDefaultOptions,
});

providers.push({
  provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
  useValue: {
    duration: 5000,
    horizontalPosition: 'end',
    verticalPosition: 'bottom',
  } as MatSnackBarConfig,
});

providers.push({
  provide: HTTP_INTERCEPTORS,
  useClass: GlobalInterceptor,
  multi: true,
});

providers.push({
  provide: ErrorHandler,
  useClass: CustomErrorHandler,
});

providers.push(CookieService);
providers.push(AuthGuard);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    FirestoreModule,
    AngularFireStorageModule,
    AngularFireModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [providers],
})
export class AppModule {}
